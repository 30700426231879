import React from "react";
import { isEmpty } from "lodash";
import "./index.less";

const IconFont = Loader.loadBaseComponent("IconFont");
const ImageView = Loader.loadBusinessComponent("ImageView");
const LabelValue = Loader.loadBaseComponent("LabelValue");
const DetailBox = Loader.loadBusinessComponent(
  "ArchivesComponents",
  "DetailBox"
);

class DetailHeader extends React.Component {
  state = {
    pictureList: [],
    imgUrl: {}
  };

  componentDidMount() {
    const { vehicleData } = this.props;
    let pictureList = [];
    vehicleData.recentAppearedUrl &&
      pictureList.push({
        url: vehicleData.recentAppearedUrl,
        type: "最近出现"
      });
      vehicleData.firstAppearedUrl &&
        pictureList.push({ url: 'data:image/jpeg;base64,' + vehicleData.firstAppearedUrl, type: "首次出现" });
    vehicleData.vehicleUrl &&
      pictureList.push({
        url: vehicleData.vehicleUrl,
        type: "代表照片"
      });
    let imgUrl = pictureList[0]
      ? { url: pictureList[0].url, type: pictureList[0].type }
      : {};
    this.setState({
      pictureList,
      imgUrl
    });
  }

  handleImg = item => {
    this.setState({
      imgUrl: item
    });
  };

  getTitle = (value, data) => {
    if (!isEmpty(data)) {
      switch (value) {
        case "车牌号码":
          return data.vehiclePlateNum;
        case "车辆颜色":
          return Array.isArray(data.vehicleColor)
            ? data.vehicleColor.map(v => Dict.getLabel("vehicleColor", v, true)).join(',')
            : Dict.getLabel("vehicleColor", data.vehicleColor, true);
        case "车辆型号":
          return (
            (data.vehicleType && Dict.getLabel("vehicleType", data.vehicleType)) || data.vehicleType
          );
        case "车辆品牌":
          return Array.isArray(data.vehicleBrand)
            ? data.vehicleBrand.map(v => Dict.getLabel("vehicleBrands", v)).join(',')
            : Dict.getLabel("vehicleBrands", data.vehicleBrand);
        case "车牌颜色":
          return Array.isArray(data.vehiclePlateColour)
            ? data.vehiclePlateColour.map(v =>
                Dict.getLabel("plateColor", v, true)
              ).join(',')
            : Dict.getLabel("plateColor", data.vehiclePlateColour, true);
        case "车系":
          return data.vehicleCatena;
        case "车主姓名":
          return data.ownerName;
        case "证件号码":
          return data.ownerIdentityNumber;
        case "手机号":
          return data.phoneNumber;
        case "地址":
          return data.residentialArea == '1' ? this.getAddress(data) : '暂无';
        default:
          return "暂无";
      }
    }
  };
  getAddress = data => {
    let address = ''
    if(data.villageName){
      address += data.villageName
    }
    if(data.ownerBuildingNumber){
      address += (data.ownerBuildingNumber + '栋')
    }
    if(data.ownerUnitNumber) {
      address += (data.ownerUnitNumber + '单元');
    }
    if(data.ownerRoomNumber) {
      address += (data.ownerRoomNumber + '室');
    }
    return address
  }
  //baseInfo
  getInfo = (name, data) => {
    let val = this.getTitle(name, data);
    return (
      <LabelValue
        label={name}
        className="info"
        value={val || "暂无"}
        fontSize={14}
      />
    );
  };
  //aidInfo
  getAidInfo = (name, val, time) => {
    return (
      <div className="info">
        <p className="info-name">{name}：</p>
        <div className="info-time">
          <div className="address">
            <IconFont type={"icon-S_Bar_Add"} theme="outlined" />
            <span className="info-val" title={val}>
              {val || "暂无"}
            </span>
          </div>
          <div className="address">
            <IconFont type={"icon-M_Bar_Clock"} theme="outlined" />
            <span className="info-val">
              {time ? Utils.formatTimeStamp(time) : "暂无"}
            </span>
          </div>
        </div>
      </div>
    );
  };

  render() {
    let { goSnop, vehicleData } = this.props;
    let { pictureList, imgUrl } = this.state;
    let {
      recentTime,
      ownerList,
      deviceName,
      firstTime,
      appearCounts,
      vehiclePlateNum
    } = vehicleData;
    return (
      <DetailBox className="vehicle-detail-header" title="基本信息：">
        <div className="detail-content">
          <div className="content-left">
            <div className="picture-box">
              <ImageView className="image" src={imgUrl.url} key={1} />
              <p className="picture-info">{imgUrl.type}</p>
            </div>
            {pictureList.slice(0, 3).map((v,i) => (
              <ImageView
                src={v.url}
                className={
                  v.url === imgUrl.url ? "mini-image active" : "mini-image"
                }
                onClick={() => this.handleImg(v)}
                key={i}
              />
            ))}
            <span
              className="mini-more"
              title="查看更多图集"
              onClick={() => goSnop()}
            >
              <IconFont type="icon-S_View_Image" />
            </span>
          </div>
          <div className="content-right">
            <div className="info-header">
              <span>车牌号码：</span>
              <strong>{vehiclePlateNum || "暂无"}</strong>
            </div>
            <div className="info-center">
              {["车辆颜色", "车辆型号", "车辆品牌", "车牌颜色", "车系"].map(v =>
                this.getInfo(v, vehicleData)
              )}
            </div>
            {ownerList &&
              ownerList.map(owner => (
                <div className="info-center">
                  {["车主姓名", "证件号码", "手机号", "地址"].map(v =>
                    this.getInfo(v, owner)
                  )}
                </div>
              ))}
            <div className="info-center">
              {this.getAidInfo("最近出现", deviceName, recentTime)}
              <span>3天内出现：{appearCounts}次</span>
              {firstTime && this.getAidInfo("首次出现", deviceName, firstTime)}
            </div>
          </div>
        </div>
      </DetailBox>
    );
  }
}
export default DetailHeader;
