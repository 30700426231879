import React from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import DetailHeader from "./components/detailHeader";
import "./index.less";

const Wrapper = Loader.loadBaseComponent("TwoColumnLayout");
const Loading = Loader.Loading;
const ActivityRule = Loader.loadBusinessComponent(
  "ArchivesComponents",
  "ActivityRule"
);
const ColleagueModal = Loader.loadBusinessComponent(
  "ArchivesComponents",
  "ColleagueModal"
);

@withRouter
@Decorator.businessProvider("tab")
class VehicleFile extends React.Component {
  constructor(props) {
    super(props);
    this.frequentList = []; // 抓拍记录
    this.state = {
      isRigested: true,
      loading: true,
      collVisible: false, // 活动规律弹窗
      vehicleData: {}, // 车辆信息
      collType: 1, // 常去地点modal类型
      currentData: {},
      trackCount: [], // 活动规律
      trackCountDays: 0, // 查询天数
      frequentLocation: [], // 常去地点
      passDeviceList:[],//轨迹统计信息
    };
  }

  componentDidMount() {
    const { history } = this.props;
    const { id } = Utils.queryFormat(history.location.search);
    LM_DB.get("parameter", id).then(data => {
      this.setState({ isRigested: data.isRigested, vehicleData: { ...data } });
      this.initVehicle(data);
    });
  }

  /**
   * @description 获取车辆详细信息
   */
  initVehicle = data => {
    return Service.community[
      data.isRigested ? "registeredVehicle" : "getTempVehicleDetail"
    ]({ vehiclePlateNum: data.vehiclePlateNum })
      .then(res => {
        let vehicleData = { ...res, ...data };
        this.setState({
          vehicleData,
          loading: false
        });
      })
      .catch(() => {
        this.setState({
          loading: false
        });
      });
  };

  //跳转抓拍记录
  goSnop = () => {
    const { tab, location } = this.props;
    const { vehicleData } = this.state;
    let id = Utils.uuid();
    LM_DB.add("parameter", {
      id,
      vehicleData
    }).then(() => {
      tab.goPage({
        moduleName: "vehicleFileSnapshot",
        location,
        data: { id }
      });
    });
  };

  //内部方法
  formatLabel(tags) {
    if (!Array.isArray(tags)) {
      return [];
    }
    let labelList = [...new Set(tags)];
    labelList = labelList.filter(v => !!v === true);
    labelList = labelList.map(v => (v = v + ""));
    return labelList;
  }

  /**
   * @description 查询aid轨迹聚合统计接口
   */
  queryTrackCount = days => {
    let { vehicleData } = this.state;
    let self = this
    let option = {
      vehiclePlateNum: vehicleData.vehiclePlateNum,
      statisticPeroidType: days === 7 ? 0 : 1
    };
    let startTime30 = moment(
      moment()
        .subtract(1,'months')
        .format("YYYY-MM-DD 00:00:00")
    ).valueOf();
    let startTime7 = moment(
      moment()
        .subtract(7,'d')
        .format("YYYY-MM-DD 00:00:00")
    ).valueOf();
    let optionAid = {
      startTime: days === 7 ? startTime7 : startTime30,
      endTime: moment().valueOf(),
      vehiclePlateNum: vehicleData.vehiclePlateNum,
      limit: 10000,
      offset: 0
    };
    Promise.all([
      Utils.catchPromise(Service.community.queryTrackCount(option)),
      Utils.catchPromise(Service.community.queryFrequenteds(option))
    ])
      .then(
        resArr => {
          const [trackRes, freRes] = resArr;
          let trackList = [];
          let frequentLocation = freRes.data.places || [];
          trackRes.data.forEach(v => {
            v.type = 0;
            trackList.push({
              name: v.deviceName,
              position: [v.longitude, v.latitude],
              count: v.count,
              cid: v.cid,
              list: Array.isArray(v.trackList)
                ? v.trackList.map(item => {
                    return {
                      name: item.deviceName,
                      position: [item.longitude, item.latitude],
                      count: item.count,
                      cid: item.cid
                    };
                  })
                : []
            });
          });
          this.setState({
            trackCount: trackList,
            trackCountDays: days,
            frequentLocation
          });
        },
        err =>
          this.setState({
            trackCount: [],
            trackCountDays: days,
            frequentLocation: []
          })
      )
      .catch(err => console.log(err));
    this.queryPassDeviceCount(days);
    Service.community.queryVehicleRecords(optionAid).then(aidRes => {
      self.frequentList = [].concat(aidRes.list);
      self.forceUpdate();
    });
  };
   /**
   * @description 查询aid经过设备次数
   */
  queryPassDeviceCount = (days) => {
    const { vehicleData, trackCount = [] } = this.state;
    const option = {
      vehiclePlateNum: vehicleData.vehiclePlateNum,
      statisticPeroidType:days === 7 ? 0 : 1,
    };
    return Service.community.queryPassDeviceCount(option).then(res => {
      let list = res || [];
      list.forEach(v => {
        v.position=v.longitude ? [v.longitude,v.latitude] : undefined
      })
      this.setState({ passDeviceList: list });
    })
  }
  //同行弹窗
  changeCOllModal = async (type, data = {}) => {
    let frequentList = this.frequentList;
    let currentData = {
      title: data.placeName || "",
      count: data.frequency || 0,
      days: data.days || 0,
      placeId: data.placeId,
      list: frequentList.filter(v => v.placeId === data.placeId)
    };
    this.setState({ currentData, collVisible: true, collType: type });
  };

  //关闭同行弹窗
  handleCollCancel = () => {
    this.setState({
      collVisible: false
    });
  };

  render() {
    let {
      vehicleData,
      trackCount,
      frequentLocation,
      loading,
      collVisible,
      currentData,
      collType,
      trackCountDays,
      passDeviceList
    } = this.state;
    if (loading) {
      return <Loading />;
    }
    return (
      <Wrapper
        className="vehicle-file"
        title={vehicleData.vehiclePlateNum || "车辆档案"}
      >
        <DetailHeader vehicleData={vehicleData} goSnop={this.goSnop} />
        <ActivityRule
          data={vehicleData}
          passDeviceList={passDeviceList}
          queryTrackCount={this.queryTrackCount}
          trackCount={trackCount}
          frequentList={this.frequentList}
          trackCountDays={trackCountDays}
          frequentLocation={frequentLocation}
          changeCOllModal={this.changeCOllModal}
        />
        {collVisible && (
          <ColleagueModal
            collVisible={collVisible}
            currentData={currentData}
            trackCountDays={trackCountDays}
            handleCollCancel={this.handleCollCancel}
            collType={collType}
          />
        )}
      </Wrapper>
    );
  }
}
export default VehicleFile;
